import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { Navbar, NavDropdown, Button } from 'react-bootstrap';
//import { useParams } from 'react-router-dom';
//import { Link } from 'react-router-dom';
//import NavDropdown from 'react-bootstrap/NavDropdown';
import InstallPWAButton from './_installPWAButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faDoorOpen } from '@fortawesome/free-solid-svg-icons';

import Logo from "../img/taggeame.svg";
import LogoInv from "../img/taggeame_w.svg";

import Instrucciones from "../pdf/TaggeaMe - Guía de Usuario.pdf";
import InstruccionesTGMEALB01 from "../pdf/TaggeaMe-Guía_de_Usuario_TGME-AL-B-01.pdf";
import InstruccionesTGMEALC01 from "../pdf/TaggeaMe-Guía_de_Usuario_TGME-AL-C-01.pdf";
import InstruccionesTGMEALS01 from "../pdf/TaggeaMe-Guía_de_Usuario_TGME-AL-S-01.pdf";
import InstruccionesTGMEALT01 from "../pdf/TaggeaMe-Guía_de_Usuario_TGME-AL-T-01.pdf";
import InstruccionesTGMESTC01 from "../pdf/TaggeaMe-Guía_de_Usuario_TGME-ST-C-01.pdf";

function NavigationBar() {
  const [deferredprompt, setDeferredPrompt] = useState(null);

  //const { pwa } = useParams();

  const navigateToUrl = (url) => {
    window.location.href = url;
  };

  const randNum = (min = 0, max = 100, excluded = [0]) => {
    let randomNumber;
    do {
      randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    } while (excluded.includes(randomNumber));

    return randomNumber;
  }

  // random number between 1-6
  const randomNumber = randNum(1, 6, [0, 3, 4]);

  const isDarkMode = [2, 4, 5, 6].includes(randomNumber);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      console.log(`'beforeinstallprompt' event was fired.`);
    };
    window.addEventListener('beforeinstallprompt', (e) => { handleBeforeInstallPrompt(e) });

    return () => {
      window.removeEventListener('beforeinstallprompt', (e) => { handleBeforeInstallPrompt(e) });
    };
  }, []);
  return (
    <Navbar expand="lg" className={`bg-logos-0${randomNumber} ${isDarkMode ? 'navbar-dark' : ''}`}>
      <Container>
        <Navbar.Brand href="./"><img src={isDarkMode ? LogoInv : Logo} alt="Taggea.me" height={50} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-start">
          <Nav className='fw-bold'>
            <Nav.Link className="text-center text-lg-start" href="#smart_tag">Smart Tag</Nav.Link>
            <Nav.Link className="text-center text-lg-start" href="#tag_preview">Tag Preview</Nav.Link>
            <Nav.Link className="text-center text-lg-start" href="#contacto">Contacto</Nav.Link>
            <Nav.Link className="text-center text-lg-start" href="#faq">Preguntas Frecuentes</Nav.Link>
            <NavDropdown className="text-center text-lg-start" title="Guía de Usuario" id="basic-nav-dropdown">
              <NavDropdown.Item href={Instrucciones} download="TaggeaMe-Guía_de_Usuario">
                Guía de Usuario (Genérico)<div className='d-inline-block circulo'><FontAwesomeIcon icon={faFilePdf} size="sm" /></div>
              </NavDropdown.Item>
              <NavDropdown.Item href={InstruccionesTGMESTC01} download="TaggeaMe-Guía_de_Usuario_TGME-ST-C-01">
                <span className="d-none d-sm-inline">Guía de Usuario - </span>Acero Circular (TGME-ST-C-01) <div className='d-inline-block circulo'><FontAwesomeIcon icon={faFilePdf} size="sm" /></div>
              </NavDropdown.Item>
              <NavDropdown.Item href={InstruccionesTGMEALB01} download="TaggeaMe-Guía_de_Usuario_TGME-AL-B-01">
                <span className="d-none d-sm-inline">Guía de Usuario - </span>Aluminio Hueso (TGME-AL-B-01) <div className='d-inline-block circulo'><FontAwesomeIcon icon={faFilePdf} size="sm" /></div>
              </NavDropdown.Item>
              <NavDropdown.Item href={InstruccionesTGMEALC01} download="TaggeaMe-Guía_de_Usuario_TGME-AL-C-01">
                <span className="d-none d-sm-inline">Guía de Usuario - </span>Aluminio Circular (TGME-AL-C-01) <div className='d-inline-block circulo'><FontAwesomeIcon icon={faFilePdf} size="sm" /></div>
              </NavDropdown.Item>
              <NavDropdown.Item href={InstruccionesTGMEALS01} download="TaggeaMe-Guía_de_Usuario_TGME-AL-S-01">
                <span className="d-none d-sm-inline">Guía de Usuario - </span>Aluminio Estrella (TGME-AL-S-01) <div className='d-inline-block circulo'><FontAwesomeIcon icon={faFilePdf} size="sm" /></div>
              </NavDropdown.Item>
              <NavDropdown.Item href={InstruccionesTGMEALT01} download="TaggeaMe-Guía_de_Usuario_TGME-AL-T-01">
                <span className="d-none d-sm-inline">Guía de Usuario - </span>Aluminio Tag Militar (TGME-AL-T-01) <div className='d-inline-block circulo'><FontAwesomeIcon icon={faFilePdf} size="sm" /></div>
              </NavDropdown.Item>

            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <InstallPWAButton deferredprompt={deferredprompt} />
            <Button variant="dark" className="login-button ms-0 ms-lg-2 mt-2 mt-lg-0" onClick={() => navigateToUrl("https://taggea.me/administracion")}>
              <FontAwesomeIcon icon={faDoorOpen} />{' '}
              Iniciar Sesión
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;