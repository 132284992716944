import { Container, Row, Col, Card } from 'react-bootstrap';
import { FacebookProvider, Page } from 'react-facebook';
import {
    DESCUENTO_ACTIVO,
    PRECIO_ST,
    PRECIO_AL,
    PRECIO_RENOVACION_ANUAL
} from '../_constants';
import { presentDiscount } from '../_functions';
//import st_medidas from '../img/tags/st_medidas_01.png';
import al_medidas from '../img/tags/al_medidas_01.png';
import st_circle from '../img/tags/st_circle.png';
import st_circle_03 from '../img/tags/st_circle_03.png';
import st_circle_04 from '../img/tags/st_circle_04.png';
import color_amarillo_oscuro from '../img/color/al_amarillo_oscuro.png';
import color_amarillo from '../img/color/al_amarillo.png';
import color_azul_oscuro from '../img/color/al_azul_oscuro.png';
import color_azul from '../img/color/al_azul.png';
import color_metalic from '../img/color/al_metalic.png';
import color_negro from '../img/color/al_negro.png';
import color_purpura from '../img/color/al_purpura.png';
import color_rojo from '../img/color/al_rojo.png';
import color_rosa from '../img/color/al_rosa.png';
import color_verde_claro from '../img/color/al_verde_claro.png';
import color_verde from '../img/color/al_verde.png';

function Intro() {
    return (
        <div className="bg-logos-03" id="smart_tag">
            <Container className="pt-5">
                <Row className="mb-2 mx-2">
                    <Col md={6} className='mb-2'>
                        <h1>Smart Tag de <span className="f-taggeame">TaggeaMe</span></h1>
                        <div>Mantén la información de la plaquita de identificación de tu mascota actualizada en todo momento.</div>
                        <div>Brinda información de salud y contacto através de un código QR, único para tu mascota.</div>
                        <div>Actualiza los datos desde tu celular, tableta o computadora.</div>
                        <ul style={{ listStyle: "disc" }}>
                            <li>Tag circular de acero Inoxidable (1 año de servicio para nuevos usuarios / mascotas):<br />{presentDiscount(PRECIO_ST)} + envío{(DESCUENTO_ACTIVO)?'<br />(Descuento exclusivo página web)':''}.</li>
                            <li>Tag aluminio (1 año de servicio para nuevos usuarios / mascotas):<br />{presentDiscount(PRECIO_AL)} + envío{(DESCUENTO_ACTIVO)?'<br />(Descuento exclusivo página web)':''}.</li>
                            <li>Año de servicio TaggeaMe:<br />{presentDiscount(PRECIO_RENOVACION_ANUAL)} {(DESCUENTO_ACTIVO)?'(Descuento exclusivo página web).':''}</li>
                            <li>Personalización: depende de dificultad.</li>
                        </ul>
                    </Col>

                    <Col md={6}>
                        <Row className="p-0 m-0">
                            <Col xs={6} md={12} lg={6}>
                                <img className="w-100 px-2 px-md-5 px-lg-0" src={st_circle_03} alt="Smart Tag Frontal" />
                            </Col>
                            <Col xs={6} md={12} lg={6}>
                                <img className="w-100 px-2 px-md-5 px-lg-0" src={st_circle_04} alt="Smart Tag Anverso" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                </Container>
                <Container fluid>
                <Row className="justify-content-between">
                    <Col xs={{ order: 0 }} md={5} lg={4} >
                        <Card className="w-100">
                            <Card.Img className="px-5 px-md-0 px-lg-5" variant="top" src={st_circle} />
                            <Card.Body>
                                <Card.Title>Tag de Acero Inoxidable *</Card.Title>
                                <Card.Text>
                                    <span className="d-block">Tag circular de acero Inoxidable 304, grabado a láser. El color de grabado es negro, gris, o café oscuro.</span>
                                    <small className="d-block">⌀ = 3.5cm.</small>
                                    <small className="d-block">* Medidas pueden variar por existencias.</small>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={{ span: 12, order: 2 }} lg={{ span: 4, order: 1 }}>
                        <Card className="w-100">
                            <Card.Img className="px-lg-5" variant="top" src={al_medidas} />
                            <Card.Body>
                                <Card.Title>Tags de Aluminio **</Card.Title>
                                <Card.Text>
                                    <span className="d-block">Tags de Aluminio anodizado de varios colores. El color de grabado es blanco.</span>
                                    <span className="d-blockm-2">
                                        <span className='color m-1' style={{ backgroundImage: `url("${color_amarillo_oscuro}")` }} title="amarillo oscuro"></span>
                                        <span className='color m-1' style={{ backgroundImage: `url("${color_amarillo}")` }} title="amarillo"></span>
                                        <span className='color m-1' style={{ backgroundImage: `url("${color_azul_oscuro}")` }} title="azul oscuro"></span>
                                        <span className='color m-1' style={{ backgroundImage: `url("${color_azul}")` }} title="azul"></span>
                                        <span className='color m-1' style={{ backgroundImage: `url("${color_metalic}")` }} title="metalic"></span>
                                        <span className='color m-1' style={{ backgroundImage: `url("${color_negro}")` }} title="negro"></span>
                                        <span className='color m-1' style={{ backgroundImage: `url("${color_purpura}")` }} title="púrpura"></span>
                                        <span className='color m-1' style={{ backgroundImage: `url("${color_rojo}")` }} title="rojo"></span>
                                        <span className='color m-1' style={{ backgroundImage: `url("${color_rosa}")` }} title="rosa"></span>
                                        <span className='color m-1' style={{ backgroundImage: `url("${color_verde_claro}")` }} title="verde claro"></span>
                                        <span className='color m-1' style={{ backgroundImage: `url("${color_verde}")` }} title="verde"></span>
                                    </span>
                                    <small className="d-block">** Medidas y/o colores pueden variar por existencias.</small>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={{ span: 7, order: 1 }} lg={{ span: 4, order: 2 }}>
                        <Card>
                            <Card.Body className="mx-2">
                                <center>
                                    <FacebookProvider appId="294542083949746">
                                        <Page
                                            href="https://www.facebook.com/profile.php?id=61553642534404"
                                            tabs="timeline"
                                            small-header="true"
                                            adapt-container-width="true"
                                            hide-cover="true"
                                            show-facepile="false"
                                            share="true"
                                            colorscheme="dark"
                                        />
                                    </FacebookProvider>
                                </center>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Intro;