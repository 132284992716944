import React, { useRef, useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import video_mockup from '../video/mockup_admin.mp4';

function MockupAdmin() {
    const [videoHeight, setHeight] = useState(null);
    const videoRef = useRef(null);

    const handleResize = () => {
        if (videoRef.current) {
            const newHeight = videoRef.current.clientHeight ?? null;
            setHeight(newHeight);
            videoRef.current.setAttribute('data-h', newHeight ?? 'auto');
        }
    };

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', () => {handleResize()});

        return () => {
           window.removeEventListener('resize', handleResize());
        };
    }, []);

    return (
        <Container fluid className="m-0 p-0 video-container" style={{height: (videoHeight === null) ? 'auto': `${videoHeight-5}px`}}>
            <video autoPlay loop muted className="mockup-admin-video" ref={videoRef} onLoadedMetadata={handleResize}>
                <source src={video_mockup} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </Container>
    );
}

export default MockupAdmin;