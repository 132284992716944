import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';
import {
    DESCUENTO_ACTIVO,
    SIMBOLO_DINERO,
    MONEDA_MEXICANA,
    DESCUENTO_PORCIENTO
} from './_constants';

export function customRound(number, digits = 2) {
    // Check if the number is already an integer
    if (Number.isInteger(number) || number % 1 === 0.50) {
        return (number).toFixed(digits); // No rounding needed
    }

    // Round up to the nearest 1
    if (number % 1 > 0.50 && number % 1 < 1) {
        return (Math.ceil(number * 2) / 2).toFixed(digits);
    }

    // Round down to the nearest whole number
    return (Math.floor(number)).toFixed(digits);
}

export function precioConDescuento(precioOriginal, porcientoDescuento = DESCUENTO_PORCIENTO) {
    return customRound(precioOriginal - ((porcientoDescuento / 100) * precioOriginal));
}

export function presentDiscount(precio, porcientoDescuento = DESCUENTO_PORCIENTO, w = '2em', fs = '0.6rem', faIcon = faCertificate, faColor = 'var(--bs-danger)') {
    const precioDescuento = precioConDescuento(precio);
    if (DESCUENTO_ACTIVO) { // Hay descuento
        return (<span>
            <strike>{SIMBOLO_DINERO}{customRound(precio)}{' '}{MONEDA_MEXICANA}</strike>{' '}
            <span className="fa-layers fa-fw text-center" style={{ width: `${w}` }}>
                <FontAwesomeIcon icon={faIcon} style={{ color: `${faColor}`, fontSize: `${w}` }} />{' '}
                <span className="fa-layers-text fa-inverse fw-bold" style={{ fontSize: `${fs}` }}>
                    {porcientoDescuento}%
                </span>
            </span>{' '}
            <Badge pill bg="success">{SIMBOLO_DINERO}{precioDescuento}{' '}{MONEDA_MEXICANA}</Badge>
        </span>);
    } else { // NO HAY DESCUENTO
        return (<span>
             <Badge pill bg="success">{SIMBOLO_DINERO}{customRound(precio)}{' '}{MONEDA_MEXICANA}</Badge>
        </span>);
    }
}
