function Footer() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return (
        <footer className="pt-4 pb-5 px-3 px-md-5 text-center d-block" style={{backgroundColor: "var(--bs-dark)", color: "white"}} >
            <span className="float-start"><a className="text-white" href="https://taggea.me/aviso_de_privacidad.php" rel="noreferrer" target="_self">Aviso de Privacidad</a></span>
            <span className="float-start float-md ms-4"><a className="text-white" href="https://taggea.me/vcard" rel="noreferrer" target="_self">QR VCard</a></span>
            <span className="float-end">TaggeaMe &copy; <span className="d-none d-sm-inline">2022 - </span>{currentYear}</span>
        </footer>
    );
}

export default Footer;