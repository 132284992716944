import React from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import bg_sally from '../img/sally_carro.jpg';
import bg_perro_juguete from '../img/perro_juguete.jpg';
import bg_perro_naranja from '../img/perro_naranja.jpg';
import bg_geolocalizacion from '../img/geolocalizacion.jpg';
import st_circle_02 from '../img/tags/st_circle_02.png';
import st_circle_05 from '../img/tags/st_circle_05.png';
import al_bone_03 from '../img/tags/al_bone_03.png';
import al_star_01 from '../img/tags/al_star_01.png';
import al_star_02 from '../img/tags/al_star_02.png';
import video_mockup from '../video/mockup.mp4';


function CarouselPrincipal() {

  return (
    <>
      <Carousel pause="hover">
        <Carousel.Item interval={10000} data-item="1">
          <Container className="mt-5 animate__animated animate__fadeIn animate__delay-1s" >
            <Row className="justify-content-between">
              <Col md={4} className="text-white">
                <h3>La información de tu mascota siempre actualizada</h3>
                <p className="d-none d-md-block">
                  Con TaggeaMe mantén tu información de contacto o información importante de tu mascota actualizada en tiempo real.
                </p>
              </Col>
              <Col md={4} className="animate__animated animate__swing animate__slower animate__delay-1s animate__repeat-1">
                <img
                  className="d-block w-100 animate__animated animate__fadeInRight animate__delay-1s right collar-01"
                  src={st_circle_05}
                  alt="Collar con Taggea.me Tag"
                />
              </Col>
            </Row>
          </Container>
          <video autoPlay loop muted className="carousel-video">
            <source src={video_mockup} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Carousel.Item>

        <Carousel.Item style={{ backgroundImage: `url("${bg_sally}")` }} interval={10000} data-item="2">
          <Container className="mt-5 animate__animated animate__fadeIn animate__delay-1s text-warning" >
            <Row className="justify-content-between">
              <Col md={6} lg={4} className="text-end text-md-start">
                <h3>Personaliza el tag de tu mascota a tu gusto...</h3>
              </Col>
              <Col md={6} className="animate__animated animate__swing animate__slower animate__delay-1s animate__repeat-1 text-end">
                <img
                  className="collar-02 d-block animate__animated animate__fadeInRight animate__delay-1s"
                  src={st_circle_02}
                  alt="Collar con Taggea.me Tag"
                />
              </Col>

            </Row>
          </Container>
        </Carousel.Item>

        <Carousel.Item style={{ backgroundImage: `url("${bg_perro_juguete}")` }} interval={10000} data-item="3">
          <Container className="mt-5 animate__animated animate__fadeIn animate__delay-1s">
            <Row className="justify-content-between">
              <Col md={4} className="mt-3 text-end text-md-start tag-al-text">
                <h3>Tambien hay diferentes formas para hacer su tag, en Aluminio</h3>
                <div className='d-none d-md-block'>
                  <p>tenemos estrellas, circulos y huesitos en aluminio, aparte de nuestro Tag circular en acero.</p>
                  <small className="d-none d-lg-inline-block">El grabado en Aluminio es color blanco, mientras que en acero es oscuro.</small>
                </div>
              </Col>
              <Col sm={4} className="animate__animated animate__bounce animate__slower animate__delay-1s animate__repeat-1">
                <img
                  className="bone-01 d-block animate__animated animate__fadeInDown animate__delay-1s"
                  src={al_bone_03}
                  alt="Tag de Huesito Taggea.me"
                />
              </Col>
              {/*<Col sm={4}>
                  <img
                    className="star-02 d-block animate__animated animate__fadeInRight animate__delay-2s mt-5 align-end"
                    src={al_star_01}
                    alt="Tag de Estrella Taggea.me"
                  />
                </Col>*/}
            </Row>
          </Container>
        </Carousel.Item>

        <Carousel.Item className="bg-perro-naranja" style={{ backgroundImage: `url("${bg_perro_naranja}")` }} interval={10000} data-item="4">
          <Container className="mt-5 animate__animated animate__fadeIn animate__delay-1s text-white">
            <Row>
              <Col md={6} className="mt-md-5 pt-md-5">
                <h3 className="mt-5"><span className="mt-md-5 pt-md-5">Los Tags son grabados por los dos lados</span></h3>
              </Col>
              <Col xs={6} md={3}>
                <img
                  className="d-block w-100 animate__animated animate__fadeInRight animate__delay-1s"
                  src={al_star_02}
                  alt="Tag de Estrella Taggea.me"
                />
              </Col>
              <Col xs={6} md={3}>
                <img
                  className="d-block w-100 animate__animated animate__fadeInRight animate__delay-2s mt-5"
                  src={al_star_01}
                  alt="Tag de Estrella Taggea.me"
                />
              </Col>
            </Row>
          </Container>
        </Carousel.Item>

        <Carousel.Item style={{ backgroundImage: `url("${bg_geolocalizacion}")` }} interval={10000} data-item="5">
          <Container className="mt-5 animate__animated animate__fadeIn animate__delay-1s text-success" >
            <Row className="justify-content-between">
              <Col md={6} lg={4} className="text-end text-md-start">
                <h3>TaggeaMe, permite que quien escanee el Tag de tu mascota pueda enviar su localización por WhatsApp</h3>
              </Col>

            </Row>
          </Container>
        </Carousel.Item>

      </Carousel>
    </>
  );
}

export default CarouselPrincipal;
