import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { QRCodeSVG } from 'qrcode.react';

import LogoInv from "../../img/taggeame_w.svg";

function TagCircularAluminio(props) {
    const { fontFamily, name, displayedName, left, right, fontSize, qrProps2 } = props;

    return (
        <Row className="justify-content-center py-3">
            <Col xs={6} md={4} lg={3} className="tag-container preview aluminio">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="80" height="80" preserveAspectRatio="xMidYMin slice" overflow="visible" id="tag_circular_aluminio_1">
                    <defs>
                        <path id="circle-left" d="M 40.5, 80.5 m 8, 6 a 37,37 0 1,1 1,0 a 37,37 0 1,1 0,0"></path>
                        <path id="circle-top" d="M 0, 125 m 0, 0 a 35,35 0 1,1 100,0 a 35,35 0 1,1 -100,0"></path>
                        <path id="circle-right" d="M 50, 50 m -37, 0 a 37,37 0 1,1 74,0 a 37,37 0 1,1 -74,0"></path>
                        <path id="circle-bottom" d="M 50, 7 m 0, 0 a 40,41 0 0,0 0,82 a 40,41 0 0,0 0,-82"></path>
                    </defs>
                    <text fontSize="5.5">
                        <textPath xlinkHref="#circle-left" startOffset="33%" textAnchor="middle" id="left_text_c_al">{left}</textPath>
                        <textPath xlinkHref="#circle-top" startOffset="25%" textAnchor="middle" fontSize="12" id="name_text_c_al" className={`d-none ${fontFamily}`}>{name || displayedName}</textPath>
                        <textPath xlinkHref="#circle-right" startOffset="40%" textAnchor="middle" id="right_text_c_al">{right}</textPath>
                        <textPath xlinkHref="#circle-bottom" startOffset="50%" textAnchor="middle" id="bottom_text_c_al">https://taggea.me/r/P2u3b4</textPath>
                    </text>
                    <circle cx="50" cy="12" r="4" stroke="#000" strokeWidth="0.25" fill="#000" />
                </svg>
                <div className="qr">
                    <QRCodeSVG {...qrProps2} />
                </div>
            </Col>
            <Col xs={6} md={4} lg={3} className="tag-container preview aluminio" >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="80" height="80" preserveAspectRatio="xMidYMin slice" overflow="visible" id="tag_circular_aluminio_2">
                    <defs>
                        <path id="circle-top2" d="M 0, 100 m 0, 0 a 35,35 0 1,1 100,0 a 35,35 0 1,1 -100,0"></path>

                        <path id="circle-bottom2" d="M 50, 7 m 0, 0 a 40,41 0 0,0 0,82 a 40,41 0 0,0 0,-82"></path>

                    </defs>
                    <text fontSize={fontSize} x="50%" y="48%" dominantBaseline="middle" textAnchor="middle" id="name_text4_c_al" className={fontFamily}>{name || displayedName}</text>
                    <text fontSize="5.5">
                        <textPath xlinkHref="#circle-top2" startOffset="25%" textAnchor="middle" fontSize={fontSize} id="name_text2_c_al" className={`d-none ${fontFamily}`}>{name || displayedName}</textPath>

                        <textPath xlinkHref="#circle-bottom2" startOffset="50%" textAnchor="middle" id="bottom_text_c_al">https://taggea.me/r/P2u3b4</textPath>
                    </text>
                    <circle cx="50" cy="12" r="4" stroke="#000" strokeWidth="0.25" fill="#000" />
                </svg>
                <div className="logoTag">
                    <img src={LogoInv} alt={"TaggeaMe"} />
                </div>
            </Col>
        </Row>
    );
}

export default TagCircularAluminio;