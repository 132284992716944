import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid, faApple, faWindows, faLinux } from '@fortawesome/free-brands-svg-icons';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

const InstallPWAButton = (props) => {
    const [userAgentIcon, setUserAgentIcon] = useState(faQuestion);
    const { deferredprompt, className } = props;

    const checkUserAgent = () => {
        const userAgent = window.navigator.userAgent;

        if (userAgent.match(/Android/i)) {
            return 'Android';
        } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
            return 'iOS';
        } else if (userAgent.match(/Linux/i)) {
            return 'Linux';
        } else if (userAgent.match(/Mac/i)) {
            return 'MacOS';
        } else if (userAgent.match(/Windows/i)) {
            return 'Windows';
        } else {
            return 'Unknown';
        }
    }

    const handleButtonIcons = () => {
        const userAgent = checkUserAgent();

        switch (userAgent) {
            case 'Android':
                setUserAgentIcon(faAndroid);
                break;
            case 'Windows':
                setUserAgentIcon(faWindows);
                break;
            case 'MacOS': case 'iOS':
                setUserAgentIcon(faApple);
                break;
            case 'Linux':
                setUserAgentIcon(faLinux);
                break;
            case 'Unknown': default:
                setUserAgentIcon(faQuestion);
                break;
        }
    }

    const handleInstall = async (deferredprompt) => {
        if (deferredprompt) {
            // Muestre el mensaje de instalación
            deferredprompt.prompt();

            // Espera a que el usuario responda al mensaje
            const { outcome } = await deferredprompt.userChoice;
            // De manera opcional, envía analíticos del resultado que eligió el usuario
            console.log(`User response to the install prompt: ${outcome}`);
            // Como ya usamos el mensaje, no lo podemos usar de nuevo, este es descartado
            deferredprompt = null;
        }
    };

    useEffect(() => {
        handleButtonIcons();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {deferredprompt && (
                <Button className={`me-lg-2 ${className}`} {...props} variant="info" onClick={() => { handleInstall(deferredprompt) }}>
                    Instalar Administrador{' '}
                    <FontAwesomeIcon icon={userAgentIcon} />
                </Button>
            )}
        </>
    );
};
export default InstallPWAButton;