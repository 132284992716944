import { precioConDescuento } from './_functions';

export const READ_URL = 'https://taggea.me/r/';

export const SIMBOLO_DINERO = '$';
export const MONEDA_MEXICANA = 'MXN';

export const PRECIO_ST = 235.50;
export const PRECIO_AL = 176.50;
export const PRECIO_RENOVACION_ANUAL = 118;

export const DESCUENTO_ACTIVO = false;
export const DESCUENTO_PORCIENTO = 15;
export const DESCUENTO_PORCIENTO_STR = `${DESCUENTO_PORCIENTO}%`;

export const PRECIO_ST_STR = `${SIMBOLO_DINERO}${PRECIO_ST.toFixed(2)} ${MONEDA_MEXICANA}`;
export const PRECIO_AL_STR = `${SIMBOLO_DINERO}${PRECIO_AL.toFixed(2)} ${MONEDA_MEXICANA}`;
export const PRECIO_RENOVACION_ANUAL_STR = `${SIMBOLO_DINERO}${PRECIO_RENOVACION_ANUAL.toFixed(2)} ${MONEDA_MEXICANA}`;

export const PRECIO_ST_DESCUENTO = precioConDescuento(PRECIO_ST);
export const PRECIO_AL_DESCUENTO = precioConDescuento(PRECIO_AL);
export const PRECIO_RENOVACION_ANUAL_DESCUENTO = precioConDescuento(PRECIO_RENOVACION_ANUAL);

export const PRECIO_ST_DESCUENTO_STR = `${SIMBOLO_DINERO}${PRECIO_ST_DESCUENTO} ${MONEDA_MEXICANA}`;
export const PRECIO_AL_DESCUENTO_STR = `${SIMBOLO_DINERO}${PRECIO_AL_DESCUENTO} ${MONEDA_MEXICANA}`;
export const PRECIO_RENOVACION_ANUAL_DESCUENTO_STR = `${SIMBOLO_DINERO}${PRECIO_RENOVACION_ANUAL_DESCUENTO} ${MONEDA_MEXICANA}`;
