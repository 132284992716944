import React, { useState, useEffect } from 'react';
import { Carousel, Container, Row, Col, Form, FormControl, InputGroup } from 'react-bootstrap';
import TagCircularAcero from './svgTagComponents/_tagCircularAcero';
import TagCircularAluminio from './svgTagComponents/_tagCircularAluminio';
import TagEstrellaAluminio from './svgTagComponents/_tagEstrellaAluminio';
import TagHuesoAluminio from './svgTagComponents/_tagHuesoAluminio';
import TagMilitarAluminio from './svgTagComponents/_tagMilitarAluminio';

const TagPreview = () => {
    const [left, setLeft] = useState('(+52) 33 1234 5678');
    const [right, setRight] = useState('(+52) 33 8765 4321');
    const [name, setName] = useState('');
    const [displayedName, setDisplayedName] = useState('Bonnie');
    const [inputName, setInputName] = useState('');
    const [fontFamily, setFontFamily] = useState('billion-dreams');
    const [fontSize, setFontSize] = useState(22);
    const [tagProps, setTagProps] = useState({
        fontFamily: fontFamily,
        name: name,
        displayedName: displayedName,
        left: left,
        right: right,
        fontSize: fontSize,
        qrProps: {
            value: `https://taggea.me/r/P2u3b4`,
            fgColor: "#2B1700",
            bgColor: "transparent",
            size: "500",
            level: "M",
            includeMargin: false/*,
            imageSettings: {
                src: Logo,
                x: undefined,
                y: undefined,
                width: 55,
                height: 26,
                excavate: true,
            }*/
        },
        qrProps2: {
            value: `https://taggea.me/r/P2u3b4`,
            fgColor: "#FFFFFF",
            bgColor: "transparent",
            size: "500",
            level: "M",
            includeMargin: false
        },
    });

    const getRandomNumber = (min = 0, max = 1) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const addSpaces = (inputString, interval) => {
        if (typeof inputString !== 'string' || typeof interval !== 'number') {
            console.log("Invalid input. Please provide a valid string and a number as the interval.");
            return "Err!";
        }

        let result = '';
        for (let i = 0; i < inputString.length; i++) {
            result += inputString[i];
            if ((i + 1) % interval === 0 && i !== inputString.length - 1) {
                result += ' ';
            }
        }

        return result;
    }

    // Function to get a random name from the popularNames array
    // eslint-disable-next-line
    const getRandomName = () => {
        const popularNames = [
            'Max', 'Bella', 'Charlie', 'Lucy', 'Cooper', 'Luna', 'Buddy', 'Daisy', 'Rocky', 'Sadie', 'Sally', 'Nelea', 'Blacky', 'Bonnie',
            'Bailey', 'Molly', 'Tucker', 'Maggie', 'Bear', 'Sophie', 'Duke', 'Zoe', 'Oliver', 'Chloe', 'Apolo', 'Duque', 'Lucky',
            'Whiskers', 'Mittens', 'Tiger', 'Smokey', 'Luna', 'Felix', 'Shadow', 'Oreo', 'Cleo', 'Simba',
            'Milo', 'Nala', 'Leo', 'Misty', 'Tigger', 'Chloe', 'Charlie', 'Lola', 'Jasper', 'Coco',
            'Chico', 'Lola', 'Frida', 'Pancho', 'Mocha', 'Paco', 'Canela', 'Chiquito', 'Lupita', 'Che', 'Chaz', 'Sam',
            'Coco', 'Mia', 'Diego', 'Mocha', 'Rosie', 'Lucky', 'Guapo', 'Bonita', 'Santo', 'Dulce', 'Chikis', 'Solovino', 'Lucio',
            'Gato', 'Luna', 'Azucar', 'Fiesta', 'Pinto', 'Chispa', 'Taco', 'Sombra', 'Estrella', 'Margarita',
            'Sofia', 'Cielo', 'Tequila', 'Amigo', 'Nube', 'Sol', 'Mariposa', 'Lindo', 'Espresso', 'Dulce'
        ];
        const randomIndex = getRandomNumber(0, popularNames.length - 1);
        return popularNames[randomIndex];
    };

    // Function to get a random name from the popularNames array
    // eslint-disable-next-line
    const getRandomPhone = (countryCode = "+52", stateCode = "33") => {
        const randomNumber = getRandomNumber(10000000, 99999999).toString();
        return `(${countryCode}) ${stateCode} ${addSpaces(randomNumber, 4)}`;
    };

    // eslint-disable-next-line
    const getFontSize = (strLength = 10) => {
        let newSize = fontSize || 22;

        switch (fontFamily) {
            case 'baskerville':
                //                      7-8                                    5-6                              0-4           9-10
                newSize = strLength > 6 && strLength <= 8 ? 13 : strLength > 4 && strLength <= 6 ? 17 : strLength <= 4 ? 25 : 11;
                break;
            case 'candara':
                //              7-10                5-6              0-4
                newSize = strLength > 6 ? 20 : (strLength > 4 ? 30 : 32);
                break;
            case 'gothic':
                //                      5-6                            0-4             7-10
                newSize = strLength > 4 && strLength <= 6 ? 16 : strLength <= 4 ? 23 : 9;
                break;
            case 'condiment':
                //                      5-6                               0-4              7-10
                newSize = (strLength > 4 && strLength <= 6) ? 22 : (strLength <= 4) ? 28 : 20;
                break;
            case "billion-dreams": case 'sedwick': default:
                //                     5-6                                0-4           7-10
                newSize = strLength > 4 && strLength <= 6 ? 28 : (strLength <= 4 ? 32 : 22);
                break;
        }
        return newSize;
    }

    const generarNombreAleatorio = (conApellido = false) => {
        const nombresAleatorios = ["Pedro", "Ana", "Luis", "Elena", "Miguel", "Sofía", "Diego", "Valeria", "Javier", "Isabella", "Andrés", "Camila", "Fernando", "Lucía", "José", "Martina", "María", "Lourdes"];
        const apellidosAleatorios = ["Gómez", "Rodríguez", "Pérez", "Fernández", "López", "González", "Martínez", "Sánchez", "Ramírez", "Díaz", "Rojas", "Macías"];

        const nombre = nombresAleatorios[Math.floor(Math.random() * nombresAleatorios.length)];
        const apellido = apellidosAleatorios[Math.floor(Math.random() * apellidosAleatorios.length)];

        return conApellido ? `${nombre} ${apellido}` : nombre;
    }

    // Function to handle input change
    const handleInputNameChange = (event) => {
        const inputValue = event.target.value;
        const filteredValue = inputValue.replace(/[^a-zA-ZáéíóúñüçÁÉÍÓÚÑÜÇ\s]/g, '').substring(0, 10);
        const petName = getRandomName();
        setInputName(filteredValue);
        setDisplayedName(filteredValue);
        setFontSize(getFontSize(filteredValue.length));
        setTagProps({
            left: left,
            right: right,
            fontSize: fontSize,
            fontFamily: fontFamily,
            name: filteredValue === '' ? petName : filteredValue,
            displayedName: filteredValue === '' ? petName : filteredValue,
            qrProps: tagProps.qrProps,
            qrProps2: tagProps.qrProps2
        });
    };

    const handleSelectFamilyChange = (event) => {
        setFontFamily(event.target.value);
        setInputName(displayedName);
        const petName = getRandomName();
        setTagProps({
            left: left,
            right: right,
            fontSize: fontSize,
            fontFamily: event.target.value,
            name: inputName === '' ? petName : inputName,
            displayedName: displayedName,
            qrProps: tagProps.qrProps,
            qrProps2: tagProps.qrProps2
        });
    };

    // Function to handle form submission
    const handleFormSubmit = (event) => {
        event.preventDefault();
        setName(inputName);
        const petName = getRandomName();
        setTagProps({
            left: left,
            right: right,
            fontSize: fontSize,
            fontFamily: fontFamily,
            name: inputName === '' ? petName : inputName,
            displayedName: inputName === '' ? petName : inputName,
            qrProps: tagProps.qrProps,
            qrProps2: tagProps.qrProps2
        });
    };

    const handleFontSize = (event) => {
        const petName = getRandomName();
        setFontSize(event.target.value);
        setInputName(displayedName);

        setTagProps({
            left: left,
            right: right,
            fontSize: event.target.value,
            fontFamily: fontFamily,
            name: inputName === '' ? petName : inputName,
            displayedName: displayedName,
            qrProps: tagProps.qrProps,
            qrProps2: tagProps.qrProps2
        });
    }

    // Function to update the displayed name every 2 seconds
    useEffect(() => {
        const intervalId = setInterval(() => {
            const petName = getRandomName();
            setDisplayedName(inputName === '' ? petName : inputName);

            if (inputName === '') {
                setFontSize(getFontSize(petName.length));
            }

            setLeft((getRandomNumber()) ? getRandomPhone() : generarNombreAleatorio(getRandomNumber()));
            setRight(getRandomPhone());

            setTagProps({
                left: left,
                right: right,
                fontSize: fontSize,
                fontFamily: fontFamily,
                name: inputName === '' ? petName : inputName,
                displayedName: displayedName,
                qrProps: tagProps.qrProps,
                qrProps2: tagProps.qrProps2
            })
        }, 2000);

        return () => clearInterval(intervalId); // Cleanup on component unmount
        // eslint-disable-next-line
    }, [inputName, getFontSize, getRandomName, getRandomPhone]);
    return (
        <Container fluid className="bg-logos-06" id="tag_preview">
            <Row className="justify-content-center py-3">
                <Col md={8} lg={8} className="">
                    <h2 className="text-white py-2">Tag Preview *</h2>
                    <p>Previsualiza cómo se vería el nombre de tu mascota en uno de nuestros Smart Tag de Acero inoxidable</p>

                </Col>
                <form onSubmit={handleFormSubmit}>
                    <Container fluid>
                        <Row>
                            <Col sm={6} md={4}>
                                <Form.Label>Nombre de Mascota</Form.Label>
                                <InputGroup className="mb-3">
                                    {/*<InputGroup.Text className="d-none d-md-inline">Nombre</InputGroup.Text>*/}
                                    <FormControl
                                        type="search"
                                        placeholder="Escribe un nombre de mascota"
                                        aria-label="Escribe un nombre de mascota"
                                        value={inputName}
                                        name={"nombre"}
                                        onChange={(e) => { handleInputNameChange(e) }}
                                        onInput={handleInputNameChange}
                                        maxLength={10}
                                    />
                                </InputGroup>
                            </Col>
                            <Col sm={6} md={4}>
                                <Form.Group controlId="tipografia">
                                    <Form.Label>Tipografía</Form.Label>
                                    <Form.Control as="select" defaultValue={fontFamily} onChange={(e) => handleSelectFamilyChange(e)}>
                                        <option value="billion-dreams">Billion Dreams</option>
                                        <option value="sedwick-ave">Sedwick Ave</option>
                                        <option value="condiment">Condiments</option>
                                        <option value="gothic">Century Gothic</option>
                                        <option value="candara">Candara</option>
                                        <option value="baskerville">Baskerville</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={4} className={'mt-3'}>
                                <Form.Label>Tamaño de Tipografía</Form.Label>
                                <Form.Range min={9} max={32} onChange={(e) => { handleFontSize(e) }} value={fontSize} />
                            </Col>
                        </Row>
                    </Container>
                </form>
            </Row>
            <Carousel pause="hover" className="carousel_tag_preview">
                <Carousel.Item interval={60000} data-item="acero">
                    <TagCircularAcero {...tagProps} />
                </Carousel.Item>

                <Carousel.Item interval={60000} data-item="aluminio">
                    <TagCircularAluminio {...tagProps} />
                </Carousel.Item>

                <Carousel.Item interval={60000} data-item="estrella_aluminio">
                    <TagEstrellaAluminio {...tagProps} />
                </Carousel.Item>
                <Carousel.Item interval={60000} data-item="hueso_aluminio">
                    <TagHuesoAluminio {...tagProps} />
                </Carousel.Item>
                <Carousel.Item interval={60000} data-item="militar_aluminio">
                    <TagMilitarAluminio {...tagProps} />
                </Carousel.Item>

            </Carousel>
            <Row>
                <Col xs={12} className="text-center">
                    <small>* Los Nombres y teléfonos aquí mostrados se generaron automáticamente y no tienen relación con información real.</small>
                </Col>
            </Row>
        </Container >
    );
};

export default TagPreview;