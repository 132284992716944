//import React, { useState, useEffect } from 'react';
import React from 'react';
import NavigationBar from './components/navigationBar';
import CarouselPrincipal from './components/carouselPrincipal';
import Intro from './components/intro';
import MockupAdmin from './components/mockupAdmin';
import FormaContacto from './components/formaContacto';
import PreguntasFrecuentes from './components/preguntasFrecuentes';
import TagPreview from './components/tagPreview';
//import Cotizador from './components/cotizador';
import Footer from './components/footer';
//import OpengraphReactComponent from 'opengraph-react';
//import Helmet from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import './styles/index.scss';

import mockup_scan from './img/mockup_scan.png';

function App() {

  /*const [contentData, setContentData] = useState({});
  const fetchOptions = {
    headers : { 
      'Content-Type': 'application/json',
      'Accept': 'application/json'
     }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('./data/landingpage.json', fetchOptions);
        const result = await response.json();
        setContentData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchData();
    
  }, []);*/

  return (
    <>
      {/*<Helmet>
        <title>TaggeaMe - Smart Tag para tu mascota.</title>
        <meta name="description"
          content="Con el Smart Tag de TaggeaMe, ten la información de tu mascota actualizada en todo momento." />
        <meta name="keywords" content="tag, smart tag, medalla, mascaotas, código qr, qr code, medallita, medalla de información, tag de información, medalla inteligente, medalla smart, medalla de localización, tag personalizable, medalla personalizable, plaquita, plaquita inteligente, plaquita smart, plaquita de información, información de contacto" />
        <meta name="author" content="CáñezDesign - Arturo Cáñez" />

        <meta property="og:site_name" content="TaggeaMe" />
        <meta property="og:title" content="Smart Tag de información para tu mascota" />
        <meta property="og:description"
          content="El Smart Tag de taggeaMe, mantiene la información de tu mascota actualizada y accesible con tan solo leer el Código QR." />
        <meta property="og:image" itemprop="image" content={og_img} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:type" content="website" />
      </Helmet>
      <OpengraphReactComponent
        site={'https://taggea.me/'}
        appId={"1bb00a81-ed16-4ae3-b943-8b9f943744dd"}
        loader={"https://taggea.me/src/img/metatag_taggeame.jpg"}
        size={'small'}
      />*/}
      <NavigationBar />
      <CarouselPrincipal />
      <Intro />
      <MockupAdmin />
      {/*<PreguntasFrecuentes data={contentData} />*/}
      <TagPreview />
      <img className="w-100 d-block" src={mockup_scan} alt="" />
      {/*<Cotizador />*/}
      <FormaContacto />
      <PreguntasFrecuentes />
      <Footer />
    </>
  );
}

export default App;
