import React, { useState, useEffect } from 'react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faPaperPlane, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import emailjs from 'emailjs-com';

const EMAILJS_USER_ID = 'NnnzYnHVAt7HxJFEo';
const EMAILJS_TEMPLATE_ID = 'template_qyg1lla';

emailjs.init(EMAILJS_USER_ID);

// Set Woking hours  
const wh = {
    start: {
        hours: 9,
        minutes: 0
    },
    end: {
        hours: 21,
        minutes: 30
    }
}

const messageNotwh = `Estimado cliente, el horario de atención de TaggeaMe es de ${wh.start.hours}:${(wh.start.minutes === 0) ? '00' : wh.start.minutes} - ${wh.end.hours}:${(wh.end.minutes === 0) ? '00' : wh.end.minutes} horas (UTC-6 | Guadalajara México); favor de mandar correo con su información de, y le atenderemos en cuanto podamos, gracias.`;

function FormaContacto() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [currentTime, setCurrentTime] = useState(new Date());
    const [isWorkingHours, setIsWorkingHours] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs
            .send('default_service', EMAILJS_TEMPLATE_ID, {
                from_name: name,
                from_email: email,
                message: message,
            })
            .then(
                (response) => {
                    console.log('Email sent successfully:', response);
                    alert('Mensaje enviado satisfactoriamente');
                    setEmail('');
                    setName('');
                    setMessage('');
                },
                (error) => {
                    console.error('Email could not be sent:', error);
                    alert('Mensaje no pudo ser enviado.');
                }
            );
    };

    const navigateToUrl = (url) => {
        window.location.href = url;
    };

    useEffect(() => {

        // Guarda el tiempo actual cada segundo.
        const timer = setInterval(() => {
            const currentDate = new Date();
            setCurrentTime(currentDate);
            handleButtonVisibility();
        }, 1000);

        const handleButtonVisibility = () => {
            // Obtenemos la hora actual
            const currentHour = currentTime.getHours();
            const currentMinutes = currentTime.getMinutes();

            // Cotejamos si está en el rango
            const isWithinTimeRange = (currentHour > wh.start.hours || (currentHour === wh.start.hours && currentMinutes >= wh.start.minutes)) && (currentHour < wh.end.hours || (currentHour === wh.end.hours && currentMinutes <= wh.end.minutes));

            // Guardamos el estado del botón
            setIsWorkingHours(isWithinTimeRange);
        };


        handleButtonVisibility((currentTime));

        return () => {
            clearInterval(timer);
        };

    }, [currentTime]);

    return (
        <Container fluid className="bg-logos-01 m-0 p-3" id="contacto">
            <Row className="justify-content-center mt-3 mb-3">
                <Col md={8} lg={6} className="d-grid gap-2">
                    <h2 className="text-white">Contacto</h2>
                </Col>
            </Row>
            <Row className="justify-content-center mt-3 mb-3">
                <Col xs={6} lg={4} xl={3} className="d-grid gap-2">
                    <Button className="mt-2 mb-3 float-end fs-6" variant="dark" size="lg" onClick={() => isWorkingHours ? navigateToUrl("tel:+523338149931") : alert(messageNotwh)}>
                        <FontAwesomeIcon icon={faPhone} /> <span className="d-none d-md-inline">+52 </span>33 3814 9931
                    </Button>
                </Col>
                {isWorkingHours && (
                    <Col xs={6} lg={4} xl={3} className="d-grid gap-2">
                        <Button className="mt-2 mb-3 float-end" variant="whatsapp" size="lg" onClick={() => { navigateToUrl("https://api.whatsapp.com/send?phone=3338149931") }}>
                            <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
                        </Button>
                    </Col>
                )}
                <Col xs={isWorkingHours ? 8 : 6} sm={6} lg={4} xl={3} className="d-grid gap-2">
                    <Button className="mt-2 mb-3 fs-6" variant="primary" size="lg" onClick={() => { navigateToUrl("mailto:contacto@taggea.me") }}>
                        <FontAwesomeIcon icon={faEnvelope} className={isWorkingHours ? '' : 'd-none d-sm-inline'} /> <span className="d-inline d-md-none">C</span><span className="d-none d-md-inline">c</span>ontacto<span className="d-none d-md-inline">@taggea.me</span>
                    </Button>
                </Col>
            </Row >
            <Row className="justify-content-center mt-3 mb-3">
                <Col md={8} lg={6}>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Form.Group as={Col} sm={6} controlId="formEmail" className="mt-2">
                                <Form.Label>Correo:</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Escriba su Correo"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} sm={6} controlId="formName" className="mt-2">
                                <Form.Label>Nombre:</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Escriba su Nombre"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Group>
                        </Row>

                        <Form.Group controlId="formComment" className="mt-2">
                            <Form.Label>Mensaje</Form.Label>
                            <Form.Control
                                as="textarea"
                                name=""
                                rows={4}
                                placeholder="Escriba su Mensaje"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </Form.Group>
                        <Button className="mt-2 mb-3 float-end" variant="dark" type="submit">
                            <FontAwesomeIcon icon={faPaperPlane} /> Enviar
                        </Button>
                    </Form>
                </Col>
            </Row>
            <div className="text-center text-dark">Ventas locales en la Zona Metropolitana de Guadalajara Jalisco, México. | Ventas Nacionales con costo de envío.</div>
        </Container >
    );
}

export default FormaContacto;