import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { QRCodeSVG } from 'qrcode.react';

import circular_Tag from '../../img/tags/circular_tag.png';
import Logo from "../../img/taggeame.svg";
//import LogoInv from "../../img/taggeame_w.svg";

function TagCircularAcero(props) {
    const { fontFamily, name, displayedName, left, right, fontSize, qrProps } = props;

    return (
        <Row className="justify-content-center py-3">
            <Col xs={6} md={4} lg={3} className="tag-container preview" style={{ background: `url(${circular_Tag}) center / contain no-repeat` }}>


                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100" preserveAspectRatio="xMidYMin slice" overflow="visible" id="tag_circular_acero_1">
                    <defs>
                        <path id="circle-left" d="M 40.5, 80.5 m 8, 6 a 37,37 0 1,1 1,0 a 37,37 0 1,1 0,0"></path>
                        <path id="circle-top" d="M 0, 125 m 0, 0 a 35,35 0 1,1 100,0 a 35,35 0 1,1 -100,0"></path>
                        <path id="circle-right" d="M 50, 50 m -37, 0 a 37,37 0 1,1 74,0 a 37,37 0 1,1 -74,0"></path>
                        <path id="circle-bottom" d="M 50, 7 m 0, 0 a 40,41 0 0,0 0,82 a 40,41 0 0,0 0,-82"></path>
                    </defs>
                    <text fontSize="12" x="50%" y="73%" dominantBaseline="middle" textAnchor="middle" id="name_text3" className={fontFamily}>{name || displayedName}</text>
                    <text fontSize="5.5">
                        <textPath xlinkHref="#circle-left" startOffset="33%" textAnchor="middle" id="left_text">{left}</textPath>
                        <textPath xlinkHref="#circle-top" startOffset="25%" textAnchor="middle" fontSize="12" id="name_text" className={`d-none ${fontFamily}`}>{name || displayedName}</textPath>
                        <textPath xlinkHref="#circle-right" startOffset="40%" textAnchor="middle" id="right_text">{right}</textPath>
                        <textPath xlinkHref="#circle-bottom" startOffset="50%" textAnchor="middle" id="bottom_text">https://taggea.me/r/P2u3b4</textPath>
                    </text>
                </svg>
                <div className="qr">
                    <QRCodeSVG {...qrProps} />
                </div>
            </Col>
            <Col xs={6} md={4} lg={3} className="tag-container preview align-self-center" style={{ background: `url(${circular_Tag}) center / contain no-repeat` }}>
                <div className="logoTag">
                    <img src={Logo} alt={"TaggeaMe"} />
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100" preserveAspectRatio="xMidYMin slice" overflow="visible" id="tag_circular_acero_2">
                    <defs>
                        <path id="circle-top2" d="M 0, 100 m 0, 0 a 35,35 0 1,1 100,0 a 35,35 0 1,1 -100,0"></path>

                        <path id="circle-bottom2" d="M 50, 7 m 0, 0 a 40,41 0 0,0 0,82 a 40,41 0 0,0 0,-82"></path>

                    </defs>
                    <text fontSize={fontSize} x="50%" y="48%" dominantBaseline="middle" textAnchor="middle" id="name_text4" className={fontFamily}>{name || displayedName}</text>
                    <text fontSize="5.5">
                        <textPath xlinkHref="#circle-top2" startOffset="25%" textAnchor="middle" fontSize={fontSize} id="name_text2" className={`d-none ${fontFamily}`}>{name || displayedName}</textPath>

                        <textPath xlinkHref="#circle-bottom2" startOffset="50%" textAnchor="middle" id="bottom_text">https://taggea.me/r/P2u3b4</textPath>
                    </text>
                </svg>
            </Col>
        </Row>
    );
}

export default TagCircularAcero;