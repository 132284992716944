import React from 'react';
import { Accordion, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import squareArrowUp from '../img/square-arrow-up.svg';
import {  
    PRECIO_ST_STR, 
    PRECIO_AL_STR, 
    PRECIO_RENOVACION_ANUAL_STR
} from '../_constants';

/*function PreguntasFrecuentes(props) {*/
function PreguntasFrecuentes() {
    /*console.log(props.data);
    const createReactElement = (item, index) => {
        if (Array.isArray(item.content)) {
            console.log('is array');
            return React.createElement(
                item.htmlTag,
                { key: index, className: item.className },
                item.content.map((contentItem, contentIndex) =>
                    typeof contentItem === 'string' ? contentItem : createReactElement(contentItem, contentIndex)
                )
            );
        } else if(typeof data === "string") {
            console.log('is array');
            return data;
        } else {
            console.log('is object');
            return React.createElement(item.htmlTag, { key: index, className: item.className }, item.content);
        }
    };

    const handleContent = (data) => {
        if (Array.isArray(data)) {
            console.log('is array');
            return data.map((item, index) => createReactElement(item, index));
        } else if (typeof data === "string") {
            console.log('is string');
            return Object.keys(data).map((item, index) => createReactElement(item, index));
        } else {
            console.log('what?');
            return data;
        }
    }*/
    return (
        <Container fluid className="p-3 bg-logos-02" id="faq">
            <Row className="justify-content-center mt-5 mb-5">
                <Col md={6}>
                    <h3 className="mb-4">Preguntas Frecuentes</h3>
                    <Accordion>
                        {/*props.faq.map(
                            (item, index) => {
                                return (
                                    <Accordion.Item key={index} eventKey={index}>
                                        <Accordion.Header content={item.question} />
                                        <Accordion.Body content={item.answer} />
                                    </Accordion.Item>
                                )
                            }
                        )*/}
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>¿Qué es TaggeaMe?</Accordion.Header>
                            <Accordion.Body>
                                TaggeaMe es una aplicación web, dedicada a gestionar la información contenida en el Smart Tag de su mascota.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>¿Qué es un Smart Tag de TaggeaMe?</Accordion.Header>
                            <Accordion.Body>
                                Es la plaquita de acero inoxidable o de aluminio anodizado con el Código QR que lleva a la página de información de su mascota.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>¿Los Smart Tags son sólo para perros?</Accordion.Header>
                            <Accordion.Body>
                                No, TaggeaMe, está pensado para cualquier tipo de mascota que requiera de una placa de identificación.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>¿Qué necesito para contratar el servicio?</Accordion.Header>
                            <Accordion.Body>
                                <span className="d-block">Puede comunicarse por teléfono (<a href="tel:+523338149931" rel="noreferrer" target="_blank">+52 33 3414 9931</a>), al WhatsApp (<a href="https://api.whatsapp.com/send?phone=3338149931" rel="noreferrer" target="_blank">3338149931</a>), por correo electrónico (<a href="mailto:contacto@taggea.me" target="_parent" rel="noreferrer">contacto@taggea.me</a>), o por la <a href="#contacto" target="_self" rel="#contacto">forma de contacto</a> en ésta página.</span>
                                <span className="d-block">Para poder crear la cuenta, se necesita:</span>
                                <ul className="d-block" style={{ listStyle: "disc" }}>
                                    <li>Correo electrónico</li>
                                    <li>Nombre de dueño de mascota</li>
                                    <li>Nombre de mascota</li>
                                    <li>Tipo de animal (ejemplo: perro, gato, conejo, etc.)</li>
                                    <li>Raza, si la conoce (ejemplo: Border Collie, raza única, etc.)</li>
                                    <li>Foto en vertical</li>
                                    <li>Especificar si requiere grabado de placa, agregar el tipo, color y forma en caso de ser de aluminio anodizado. En acero inoxidable, sólo hay color plateado y forma redonda.</li>
                                    <li>Aclarar las caracteristicas de personalización, si así se requiere</li>
                                    <li>En pedidos locales (Guadalajara Jalisco), nos comunicamos para vernos en puntos intermedios. Para pedidos foraneos, se requiere dirección de envío y el paquete se envía contra entrega o se cobra lo del envio.</li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>¿Cuanto cuesta TaggeaMe?</Accordion.Header>
                            <Accordion.Body>
                                <ul className="d-block" style={{ listStyle: "disc" }}>
                                    <li>Si eres usuario nuevo (o es una mascota nueva), el sistema de TaggeaMe viene un año de servicio gratis en la compra de una plaquita.</li>
                                    <li>Placa de acero inoxidable: {PRECIO_ST_STR} *</li>
                                    <li>Placa de aluminio: {PRECIO_AL_STR} *</li>
                                    <li>Anualidad de TaggeaMe: {PRECIO_RENOVACION_ANUAL_STR}</li>
                                </ul>
                                * Mas gastos de envío.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>¿Puedo contratar el sistema de TaggeaMe sin requerir grabado de plaquita?</Accordion.Header>
                            <Accordion.Body>
                                <span className="d-block">Si, el sistema de TaggeaMe, genera un vínculo el cual se puede accesar a él sin necesidad de usar el Código QR generado por el sistema.</span>
                                <span className="d-block">En el listado de Tags dentro de la administración de TaggeaMe, existe un botón con el cual puede imprimir el Código QR de su mascota, o puede generar uno en cualquier generador de Códigos QR utilizando el vínculo proporcionado en el listado de Tags.</span>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header>No tengo o no recuerdo mi contraseña para entrar al administrador de TaggeaMe, ¿Qué puedo hacer?</Accordion.Header>
                            <Accordion.Body>
                                Para poder cambiar la contraseña, sólo es necesario que recuerde el correo electrónico con el que se dió de alta en TaggeaMe y entrar a la página: <a href="https://taggea.me/administracion/forgot-pass.php" rel="noreferrer" target="_blank">https://taggea.me/administracion/forgot-pass.php</a>, donde necesita escribir su correo electrónico y dar clic al botón de enviar, después revisar su correo electrónico (revisar su bandeja de Spam si no ve el correo) y dar clic al vículo que se envió a su correo; después agregar su nueva contraseña y guardar su cambio.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="7">
                            <Accordion.Header>¿Cómo entro al administrador de TaggeaMe?</Accordion.Header>
                            <Accordion.Body>
                                Entrar a la página <a href="https://taggea.me/administracion/" rel="noreferrer" target="_blank">https://taggea.me/administracion/</a>, entrar con su usuario o correo electrónico y su contraseña.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="8">
                            <Accordion.Header>¿Qué hace Taggeame con mis datos personales?</Accordion.Header>
                            <Accordion.Body>
                                Taggeame tiene muy presente la seguridad de los datos de sus clientes, no vendemos los datos guardados, pero si tiene dudas de lo que TaggeaMe hace con sus datos, puede ver el aviso de privacidad en: <a href="https://taggea.me/aviso_de_privacidad.php" rel="noreferrer" target="_self">https://taggea.me/aviso_de_privacidad.php</a>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="9">
                            <Accordion.Header>¿Cómo puedo realizar mi pago de Servicios?</Accordion.Header>
                            <Accordion.Body>
                                Puede hacerlo de manera directa al recibir su Smart Tag, o por transferencia.
                                <ul className="d-block" style={{ listStyle: "disc" }}>
                                    <li>Banco: CitiBanamex</li>
                                    <li>Nombre: Arturo Cáñez González</li>
                                    <li>CLABE Interbancaria: 002320903552357921</li>
                                    <li>Referencia: TAG [TAG-ID] (Ejemplo: si su vínculo es https://taggea.me/r/Xy23f4, su TAG-ID es Xy23f4 y su referencia sería: TAG Xy23f4)</li>
                                    <li>No olvide mandar el comprobante a: WhatsApp (<a href="https://api.whatsapp.com/send?phone=3338149931" rel="noreferrer" target="_blank">3338149931</a>) o por correo electrónico (<a href="mailto:contacto@taggea.me">contacto@taggea.me</a></li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="10">
                            <Accordion.Header>¿Puedo rastrear a mi mascota con el Smart Tag?</Accordion.Header>
                            <Accordion.Body>
                                Por si solo, el Smart Tag de TaggeaMe no es un rastreador / Geolocalizador GPS en tiempo real, pero podremos saber dónde fué localizada nuestra mascota si la persona que encontró a su mascota presiona el botón "Enviar Geolocalizacion por WhatsApp" dentro de la página TaggeaMe de su mascota; al momento de hacer clic, la página pide acceso a la geolocalización y después se le abre la aplicación whatsapp con el vínculo de googlemaps listo para ser enviado y la información de geolocalización es guardada en el contador de escaneos junto con su IP dentro del administrador de TaggeaMe. Ésto debido a que para proporcionar los datos de Geolocalización, el usuario explicitamente tiene que dar permiso para que la página pueda acceder a éstos.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="11">
                            <Accordion.Header>¿Existe alguna aplicación de TaggeaMe para mi celular?</Accordion.Header>
                            <Accordion.Body>
                                <span className="d-block">Por el momento no contamos con una aplicación para Android o IOS, pero nuestro sistema es una web app progresiva, por lo que su navegador del celular, puede instalarla.</span>
                                <span className="d-block"><strong>Android - Chrome:</strong> Abre la página web de TaggeaMe (https://taggea.me/) y dentro del navegador, busca en la parte superior derecha el icono <FontAwesomeIcon icon={faEllipsisVertical} /> <i>(icono de 3 puntos verticales)</i> y buscar la opción <strong><i>"Instalar Aplicación"</i></strong> o <strong><i>"Añadir a pantalla de inicio"</i></strong> o alguno similar y va a depender de la versión de Chrome y Android que uses, si no aparece, en el menú del navegador busca la opción <strong><i>"Instalar Aplicación"</i></strong>.</span>
                                <span className="d-block"><strong>IOS - Safari: </strong> Abre la página web de TaggeaMe (https://taggea.me/) y dentro del navegador, busca en la parte inferior el icono <img src={squareArrowUp} alt="icono de flecha arriba dentro de un cuadrado" width={16} /> <i>(icono de flecha arriba dentro de un cuadrado)</i> y busca <strong><i>"Añadir a pantalla de inicio"</i></strong>.</span>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
}

export default PreguntasFrecuentes;